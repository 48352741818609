<template>
  <div>
    <a-spin :spinning="loading">
      <div class="exam-box-main">
        <a-empty
          v-if="!loading && examList.length == 0"
          description="暂无考试记录"
        ></a-empty>
        <template v-for="(item, index) in examList" :key="index">
          <div class="exam-item">
            <div class="name" :title="item.exam_name">{{ item.exam_name }}</div>
            <div class="score">
              总分：{{ item.exam_total_score }}分<text
                style="display: inline-block; width: 30px"
              ></text
              >通过分数：<text style="color: #bb2f2f"
                >{{ item.exam_pass_score }}分</text
              >
            </div>
            <div class="school">
              {{ item.school_name }}<text class="line">|</text
              >{{ item.class_name }}
            </div>
            <div class="time">考试时间：{{ item.record_time }}</div>
            <div class="flex-between bar">
              <div class="exam_score">
                考试成绩：{{ item.record_exam_score }}
              </div>
              <div class="start-btn" @click="openExamDrawer(item)">
                查看试卷
              </div>
            </div>
          </div>
        </template>
      </div>
      <a-pagination
        v-if="!loading && examList.length > 0"
        v-model:current="page"
        v-model:pageSize="limit"
        :defaultPageSize="16"
        :hideOnSinglePage="false"
        show-size-changer
        :total="total"
        :pageSizeOptions="['16', '24', '40', '120']"
        @showSizeChange="onShowSizeChange"
      />
    </a-spin>
    <a-drawer
      title="考试详情"
      :width="720"
      :visible="drawerVisible"
      :body-style="{ paddingBottom: '80px' }"
      :closable="false"
      :destroyOnClose="true"
      :maskClosable="false"
      @close="onClose"
    >
      <template #extra>
        <a-space>
          <a-button @click="onClose">关闭</a-button>
        </a-space>
      </template>
      <div>
        <a-descriptions :title="student.exam_name" :column="3">
          <a-descriptions-item label="考试成绩" :span="1">
            {{ student.record_exam_score }}
          </a-descriptions-item>
          <a-descriptions-item label="题目总分" :span="1">
            {{ student.exam_total_score }}
          </a-descriptions-item>
          <a-descriptions-item label="合格分数" :span="1">
            {{ student.exam_pass_score }}
          </a-descriptions-item>
          <a-descriptions-item label="考试用时" :span="1">
            {{ getTime(student.record_exam_duration) }}
          </a-descriptions-item>
          <a-descriptions-item label="提交时间" :span="2">
            {{ student.record_time }}
          </a-descriptions-item>
        </a-descriptions>
        <template v-for="(item, index) in record" :key="index">
          <a-row class="question-box">
            <a-col :span="24">
              <div class="text-weight">
                {{ index + 1 }}.{{
                  item.question_type_id == 1
                    ? "【单选题】"
                    : item.question_type_id == 2
                    ? "【多选题】"
                    : item.question_type_id == 3
                    ? "【判断】"
                    : ""
                }}
              </div>
              <div v-html="item.question_title"></div>
            </a-col>
            <a-col :span="24" class="option-box">
              <a-row :gutter="12">
                <template
                  v-for="(item2, index2) in ['A', 'B', 'C', 'D', 'E', 'F']"
                  :key="index2"
                >
                  <a-col
                    :span="12"
                    v-if="item[`question_option_${index2 + 1}`]"
                    class="option-box-item"
                    :style="{
                      color:
                        item.question_option_right.indexOf(item2) > -1
                          ? '#20c5a8'
                          : item.question_record_answer.indexOf(item2) > -1
                          ? '#bb2f2f'
                          : '',
                    }"
                  >
                    {{ item2 }}.{{ item[`question_option_${index2 + 1}`] }}
                  </a-col>
                </template>
              </a-row>
            </a-col>
            <a-divider style="margin-top: 10px"></a-divider>
            <a-col :span="24" class="answer-box">
              <div>正确答案：{{ item.question_option_right }}</div>
              <div
                :style="{
                  color:
                    item.question_record_answer == item.question_option_right
                      ? '#20c5a8'
                      : '#bb2f2f',
                }"
              >
                你的答案：{{ item.question_record_answer }}
                <check-outlined
                  v-if="
                    item.question_record_answer == item.question_option_right
                  "
                  style="color: #20c5a8"
                />
                <close-outlined v-else style="color: #bb2f2f" />
              </div>
            </a-col>
            <a-divider style="margin-top: 10px"></a-divider>
            <a-col :span="24">
              <div class="text-weight">【答案解析】</div>
              <div v-html="item.question_analysis || '-'"></div>
            </a-col>
          </a-row>
        </template>
        <a-row v-if="record.length == 0" class="question-box">
          <a-empty description="暂无考试详情" style="margin: 0 auto"></a-empty>
        </a-row>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { isEqual } from "lodash-es";
// import url from "@/utils/url.js";
import moment from "moment";
import { getExamRecordList, getExamRecordData } from "@/api/main.js";
import { message } from "ant-design-vue";
import { getUserId } from "@/utils/storeData.js";

export default defineComponent({
  name: "Exam",
  components: {
    CheckOutlined,
    CloseOutlined,
  },
  setup() {
    const pageData = reactive({
      examList: [],
      page: 1,
      limit: 9,
      total: 0,
      loading: false,
      drawerVisible: false,
      record: [],
      student: {},
      user_id: computed(() => getUserId()).value,
    });
    const getCourseListFun = () => {
      pageData.loading = true;
      let data = {
        page: pageData.page,
        limit: pageData.limit,
        user_id: pageData.user_id,
      };
      getExamRecordList(data)
        .then((res) => {
          if (res.code == 200) {
            pageData.examList = res.data.exam_record;
            pageData.total = res.count;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };

    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };

    const openExamDrawer = (item) => {
      getExamRecordData({ record_id: item.record_id })
        .then((res) => {
          if (res.code == 200) {
            pageData.record = res.data.exam_question_record;
            pageData.student = res.data.exam_record;
            pageData.drawerVisible = true;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    };
    const onClose = () => {
      pageData.drawerVisible = false;
    };

    const getTime = (_time) => {
      let hours = _time >= 3600 ? Math.floor(_time / 3600) : 0;
      let minutes = _time >= 60 ? Math.floor((_time % 3600) / 60) : 0;
      let seconds = (_time % 3600) % 60;
      _time =
        `${hours.toString().padStart(2, 0)}时` +
        `${minutes.toString().padStart(2, 0)}分` +
        `${seconds.toString().padStart(2, 0)}秒`;
      return _time;
    };

    const getTimeFormat = (_time) => {
      if (_time) {
        return moment(_time * 1000).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return _time;
      }
    };

    watch([() => pageData.page, () => pageData.limit], (newValue, oldValue) => {
      if (!isEqual(newValue, oldValue)) {
        getCourseListFun();
      }
    });
    getCourseListFun();

    return {
      ...toRefs(pageData),
      onShowSizeChange,
      openExamDrawer,
      onClose,
      getTime,
      getTimeFormat,
    };
  },
});
</script>

<style lang="less" scoped>
.exam-box-main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  .ant-empty {
    margin: 0 auto;
  }
  .exam-item {
    position: relative;
    width: 302px;
    margin-right: 32px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 6px 10px 0 #5f656926;
    padding: 30px 20px;
    font-size: 14px;
    transition: all 0.2s;
    color: #333333;
    .name {
      font-size: 18px;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .score {
      margin-top: 12px;
    }

    .school {
      color: #666666;
      margin-top: 12px;
    }

    .time {
      color: #969696;
      margin-top: 12px;
      margin-bottom: 18px;
    }

    .line {
      display: inline-block;
      margin: 0 10px;
    }

    .start-btn {
      padding: 4px 12px;
      background-color: #1e83e5;
      border-radius: 28px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
    }

    .bar {
      padding-top: 16px;
      border-top: 1px solid #e0e0e0;
    }

    .exam_score {
      color: #000000;
      font-size: 15px;
    }
  }
  .exam-item:nth-child(3n) {
    margin-right: 0;
  }
  //   .exam-item:hover {
  //     box-shadow: 0 6px 10px 0 #5f656960;
  //     top: -4px;
  //   }
}
.ant-pagination {
  text-align: center;
}
</style>
<style lang="less">
.question-box {
  border: 1px solid #eee;
  margin-bottom: 12px;
  border-radius: 8px;
  padding: 20px 18px;
  .option-box {
    padding: 0 8px;
    margin-bottom: 8px;
    .option-box-item {
      margin-bottom: 4px;
    }
  }
  .answer-box {
    margin-bottom: 8px;
    padding: 0 8px;
  }
  .text-weight {
    // font-weight: 00;
    flex: 0 0 auto;
    color: #969696;
  }
}
</style>
