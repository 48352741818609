<template>
  <div>
    <a-row v-if="dataList.length > 0" :gutter="12">
      <template v-for="(item, index) in dataList" :key="index">
        <a-col :span="8">
          <div
            class="student-box box-shadow-1 flex-between"
            @click="
              () => {
                visible = true;
                chooseStudent = item;
              }
            "
          >
            <div class="student-box-left">
              <a target="_blank" class="job-name" :href="item.certificate_pdf">
                {{ item.first_job.job_name }}-{{ item.second_job.job_name }}
              </a>
              <div class="school-info">
                {{ item.certificate_time }}
              </div>
            </div>
            <download-outlined
              class="certificate-icon"
              @click="downloadPdf(item)"
            />
          </div>
        </a-col>
      </template>
    </a-row>
    <a-empty v-else description="暂无证书"></a-empty>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "@vue/runtime-core";
import { DownloadOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { getCertificateList } from "@/api/main";
import { getUserId } from "@/utils/storeData.js";

export default defineComponent({
  name: "Certificate",
  components: {
    DownloadOutlined,
  },
  props: {},
  setup() {
    const pageData = reactive({
      studentList: [
        {
          job_first_name: "工种工种工种工种工种工种工种工种工种工种",
          certificate_time: "2022-10-20 10:00",
          certificate_pdf:
            "https://yxzx-static.oss-cn-beijing.aliyuncs.com/admin/file/a149994cf9b999e0b2482a88a474a010.pdf",
          student_idcard: "130203199712272120",
          student_name: "孙金薇1",
        },
        {
          job_first_name: "工种工种工种工种工种工种工种工种工种工种",
          certificate_time: "2022-10-20 10:00",
          certificate_pdf:
            "https://yxzx-static.oss-cn-beijing.aliyuncs.com/admin/file/a149994cf9b999e0b2482a88a474a010.pdf",
          student_idcard: "130203199712272120",
          student_name: "孙金薇1",
        },
        {
          job_first_name: "工种工种工种工种工种工种工种工种工种工种",
          certificate_time: "2022-10-20 10:00",
          certificate_pdf:
            "https://yxzx-static.oss-cn-beijing.aliyuncs.com/admin/file/a149994cf9b999e0b2482a88a474a010.pdf",
          student_idcard: "130203199712272120",
          student_name: "孙金薇1",
        },
        {
          job_first_name: "工种工种工种工种工种工种工种工种工种工种",
          certificate_time: "2022-10-20 10:00",
          certificate_pdf:
            "https://yxzx-static.oss-cn-beijing.aliyuncs.com/admin/file/a149994cf9b999e0b2482a88a474a010.pdf",
          student_idcard: "130203199712272120",
          student_name: "孙金薇1",
        },
      ],
      visible: false,
      chooseStudent: {},
      loading: false,
      dataList: [],
      user_id: computed(() => getUserId()).value,
    });

    const downloadPdf = (record) => {
      let url = record.certificate_pdf,
        fileName = record.student_name + record.student_idcard + ".pdf",
        type = "pdf";
      let reg =
        /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/; // eslint-disable-line
      if (!reg.test(url)) {
        message.error("传入参数不合法,不是标准的文件链接");
      } else {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.setRequestHeader("Content-Type", `application/${type}`);
        xhr.responseType = "blob";
        xhr.onload = function () {
          if (this.status == 200) {
            //接受二进制文件流
            console.log(this);
            var blob = this.response;
            const blobUrl = window.URL.createObjectURL(blob);
            // 这里的文件名根据实际情况从响应头或者url里获取
            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(blobUrl);
          }
        };
        xhr.send();
      }
    };

    const getCertificateListFun = () => {
      pageData.loading = true;
      getCertificateList({
        user_id: pageData.user_id,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.dataList = res.data.certificate;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    getCertificateListFun();

    return {
      ...toRefs(pageData),
      downloadPdf,
    };
  },
});
</script>

<style lang="less" scoped>
.student-box {
  font-size: 16px;
  margin-bottom: 18px;
  border-radius: 8px;
  padding: 20px 18px;
  align-items: center;
  position: relative;
  border: 1px solid #eee;
  transition: all 0.2s;
  cursor: pointer;
  &-left {
    width: 90%;
    flex: 0 0 auto;
  }
  .job-name,
  .school-info {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .job-name {
    display: block;
    margin-bottom: 8px;
    padding: 0;
    color: #666;
    font-size: 16px;
  }
  .school-info {
    color: #999;
    font-size: 14px;
  }
  .certificate-icon {
    font-size: 18px;
    color: #666;
  }
  &:hover {
    border: 1px solid #1890ff60;
    box-shadow: 0 8px 13px 0 #1890ff30;
  }
  &:hover .certificate-icon {
    color: #1890ff;
  }
}
.no-image {
  width: 100px;
  height: 120px;
  background-color: #a5a5a5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 40px;
}
</style>
