<template>
  <div>
    <a-spin :spinning="loading">
      <div class="course-box-main">
        <a-empty
          v-if="!loading && courseList.length == 0"
          description="暂无课程"
        ></a-empty>
        <template v-for="(item, index) in courseList" :key="index">
          <div class="course-item" @click="goToCourse(item)">
            <img :src="item.course_image" class="course-poster" />
            <div class="course-name">{{ item.course_name }}</div>
            <div class="course-view">
              学习进度 {{ item.record_course_per }}%
            </div>
          </div>
        </template>
      </div>
      <a-pagination
        v-if="!loading"
        v-model:current="page"
        v-model:pageSize="limit"
        :defaultPageSize="16"
        :hideOnSinglePage="true"
        show-size-changer
        :total="total"
        :pageSizeOptions="['16', '24', '40', '120']"
        @showSizeChange="onShowSizeChange"
      />
    </a-spin>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
// import { EyeOutlined } from "@ant-design/icons-vue";
import { getUserCourseList } from "@/api/main.js";
import { message } from "ant-design-vue";
import { isEqual } from "lodash-es";
import url from "@/utils/url.js";
import { getUserId } from "@/utils/storeData.js";
import { encode } from "js-base64";

export default defineComponent({
  name: "Course",
  components: {},
  setup() {
    const pageData = reactive({
      courseList: [],
      page: 1,
      limit: 9,
      total: 0,
      loading: false,
      user_id: computed(() => getUserId()).value,
    });
    const getCourseListFun = () => {
      pageData.loading = true;
      let data = {
        page: pageData.page,
        limit: pageData.limit,
        user_id: pageData.user_id,
      };
      getUserCourseList(data)
        .then((res) => {
          if (res.code == 200) {
            // console.log(res.data);
            pageData.courseList = res.data.course;
            pageData.total = res.count;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };

    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };

    const goToCourse = (_course) => {
      url.navigateTo("/course/course", {
        t: encode(`${_course.course_id},${_course?.student_id}`),
      });
    };

    watch([() => pageData.page, () => pageData.limit], (newValue, oldValue) => {
      if (!isEqual(newValue, oldValue)) {
        getCourseListFun();
      }
    });
    getCourseListFun();

    return {
      ...toRefs(pageData),
      onShowSizeChange,
      goToCourse,
    };
  },
});
</script>

<style lang="less" scoped>
.course-box-main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  .ant-empty {
    margin: 0 auto;
  }
  .course-item {
    position: relative;
    width: 302px;
    margin-right: 32px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 6px 10px 0 #5f656926;
    cursor: pointer;
    transition: all 0.2s;
    .course-poster {
      width: 100%;
      height: 185px;
      border-radius: 8px 8px 0 0;
      display: block;
      margin-bottom: 8px;
    }
    .course-name {
      width: 90%;
      font-size: 15px;
      margin: 0 auto;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 8px;
      color: #545c63;
    }
    .course-view {
      width: 90%;
      margin: 0 auto;
      font-size: 13px;
      padding-bottom: 16px;
      // text-align: right;
      color: #9199a1;
    }
  }
  .course-item:nth-child(3n) {
    margin-right: 0;
  }
  .course-item:hover {
    box-shadow: 0 6px 10px 0 #5f656960;
    transform: translateY(-4px);
  }
}
.ant-pagination {
  text-align: center;
}
</style>
