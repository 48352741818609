<template>
  <div class="contact-page">
    <div v-if="!loading" v-html="htmlData"></div>
    <a-empty v-if="!htmlData && !loading" description="暂无内容"></a-empty>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "@vue/runtime-core";
import { getSettingData } from "@/api/main.js";
import { message } from "ant-design-vue";

export default defineComponent({
  name: "Contact",
  setup() {
    const pageData = reactive({
      htmlData: null,
      loading: true,
    });
    getSettingData({
      setting_id: 3,
    })
      .then((res) => {
        if (res.code == 200) {
          pageData.htmlData = res.data.setting.setting_content;
        } else {
          message.error(res.msg);
        }
      })
      .catch((res) => {
        console.log(res);
        message.error("网络有误，请稍后再试");
      })
      .finally(() => {
        pageData.loading = false;
      });

    return { ...toRefs(pageData) };
  },
});
</script>

<style>
.contact-page img {
  max-width: 100%;
}
</style>
