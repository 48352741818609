<template>
  <div class="mine-page">
    <div class="mine-banner">
      <div class="page-main mine-info">
        <!-- <a-popover placement="right">
          <template #content> 二寸照片 </template>
        </a-popover> -->
        <img
          v-if="userData.user_avatar"
          :src="userData.user_avatar"
          alt=""
          class="student-photo box-shadow-1"
        />
        <div v-else class="student-photo box-shadow-1">
          <user-outlined />
        </div>
        <div class="student-name">
          <a-space>
            {{ userData.user_name || "-" }}
            <a-tag
              v-if="userData.user_isvip"
              color="warning"
              style="position: absolute; margin-top: -14px"
              >VIP</a-tag
            >
          </a-space>
        </div>
        <div class="student-phone">
          联系电话：{{ userData.user_phone || "-" }}
        </div>
      </div>
    </div>
    <div class="page-main">
      <div class="flex" style="align-items: unset">
        <div class="mine-left">
          <div class="mine-menu">
            <div
              :class="{ 'menu-title': true, 'menu-active': menuActive == 0 }"
              @click="changeMenu(0)"
            >
              <div class="mine-menu-text">
                <img src="~@/assets/icon_0.png" alt="" />
                公告列表
              </div>
            </div>
            <div
              :class="{ 'menu-title': true, 'menu-active': menuActive == 1 }"
              @click="changeMenu(1)"
            >
              <div class="mine-menu-text">
                <img src="~@/assets/icon_5.png" alt="" />
                我的课程
              </div>
            </div>
            <div
              :class="{ 'menu-title': true, 'menu-active': menuActive == 2 }"
              @click="changeMenu(2)"
            >
              <div class="mine-menu-text">
                <img src="~@/assets/icon_2.png" alt="" />
                学员资料
              </div>
            </div>
            <div
              :class="{ 'menu-title': true, 'menu-active': menuActive == 3 }"
              @click="changeMenu(3)"
            >
              <div class="mine-menu-text">
                <img src="~@/assets/icon_7.png" alt="" />
                我的考试
              </div>
            </div>
            <div
              :class="{ 'menu-title': true, 'menu-active': menuActive == 4 }"
              @click="changeMenu(4)"
            >
              <div class="mine-menu-text">
                <img src="~@/assets/icon_8.png" alt="" />
                我的证书
              </div>
            </div>
            <div
              :class="{ 'menu-title': true, 'menu-active': menuActive == 5 }"
              @click="changeMenu(5)"
            >
              <div class="mine-menu-text">
                <img src="~@/assets/icon_4.png" alt="" />
                我的技能
              </div>
            </div>
            <div
              :class="{ 'menu-title': true, 'menu-active': menuActive == 6 }"
              @click="changeMenu(6)"
            >
              <div class="mine-menu-text">
                <img src="~@/assets/icon_3.png" alt="" />
                联系客服
              </div>
            </div>
          </div>
        </div>
        <div class="mine-right bg-white box-shadow-1">
          <notice v-if="menuActive == 0"></notice>
          <course v-if="menuActive == 1"></course>
          <student-info v-if="menuActive == 2"></student-info>
          <exam v-if="menuActive == 3"></exam>
          <certificate v-if="menuActive == 4"></certificate>
          <contact v-if="menuActive == 6"></contact>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
} from "@vue/runtime-core";
import StudentInfo from "./components/student.vue";
import Contact from "./components/contact.vue";
import Course from "./components/course.vue";
import Exam from "./components/exam.vue";
import Certificate from "./components/certificate.vue";
import { message } from "ant-design-vue";
import Notice from "./components/notice.vue";
import { UserOutlined } from "@ant-design/icons-vue";
import { getUserData } from "@/utils/storeData.js";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "MinePage",
  components: {
    StudentInfo,
    Contact,
    Course,
    Exam,
    Certificate,
    Notice,
    UserOutlined,
  },
  setup() {
    const route = useRoute();
    const pageData = reactive({
      userData: null,
      menuActive: 0,
    });

    pageData.userData = computed(() => getUserData());
    console.log("userData", pageData.userData);

    const changeMenu = (index) => {
      if (index == 5) {
        message.error("暂未开通");
      } else {
        pageData.menuActive = index;
      }
    };

    watch(
      () => route.query,
      (val) => {
        if (val?.type == "course") {
          pageData.menuActive = 1;
        }
      },
      { immediate: true }
    );

    return {
      ...toRefs(pageData),
      changeMenu,
    };
  },
});
</script>

<style lang="less" scoped>
.mine-page {
  // height: 100%;
  padding: 0 0 50px;
  min-width: 1200px;
  .mine-banner {
    background: url("~@/assets/mine_banner_1.png") no-repeat center top #000;
    background-size: cover;
    height: 148px;
    margin-bottom: 32px;
    .mine-info {
      padding: 24px;
      position: relative;
      color: #fff;
      .student-photo {
        width: 148px;
        height: 148px;
        display: block;
        margin-bottom: 12px;
        position: absolute;
        top: 32px;
        left: 0;
        z-index: 1;
        border-radius: 2px;
        background-color: #ddd;
        font-size: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .student-name {
        padding-top: 18px;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 4px;
        margin-left: 162px;
      }
      .student-phone {
        margin-bottom: 0px;
        margin-left: 162px;
      }
    }
  }

  .mine-left {
    width: 148px;
    flex: 0 0 auto;
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    margin-right: 32px;
    .mine-menu {
      width: 100%;
      .menu-title {
        height: 52px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000000d9;
        border-radius: 8px 0 0 8px;
        cursor: pointer;
        .mine-menu-text {
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          img {
            width: 24px;
            height: 24px;
            display: block;
            margin-right: 12px;
            overflow: hidden;
          }
        }
      }
      .menu-active {
        color: #fff;
        background-color: #1890ff;
        position: relative;
        img {
          -webkit-filter: drop-shadow(#fff 0 32px);
          filter: drop-shadow(#fff 0 32px);
          transform: translateY(-33px);
          //   filter: drop-shadow(#ff0000 0 32px);
        }
      }
      .menu-active::after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        right: -15px;
        width: 0;
        height: 0;
        border-top: 26px solid transparent;
        border-bottom: 26px solid transparent;
        border-left: 15px solid #1890ff;
      }
    }
  }

  .mine-right {
    border-radius: 8px;
    padding: 24px;
    width: calc(100% - 180px);
  }
}
</style>
