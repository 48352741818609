<template>
  <div class="notice-page">
    <div class="notice-box">
      <template v-for="(item, index) in notice" :key="index">
        <div class="notice-row flex-between" @click="chooseNoticeFun(item)">
          <div class="notice-title">{{ item.notice_title }}</div>
          <div class="notice-time">{{ item.notice_time }}</div>
        </div>
      </template>
      <a-empty v-if="notice.length == 0" description="暂无公告"></a-empty>
    </div>
    <a-pagination
      v-model:current="page"
      v-model:pageSize="limit"
      :defaultPageSize="16"
      :hideOnSinglePage="false"
      :total="total"
      :pageSizeOptions="['16', '24', '40', '120']"
      show-size-changer
      @showSizeChange="onShowSizeChange"
    />
    <a-modal
      title="公告详情"
      width="720px"
      v-model:visible="showNotice"
      :maskClosable="false"
      :footer="false"
      :centered="true"
      :bodyStyle="{
        height: 'calc(100vh - 180px)',
        overflowY: 'auto',
      }"
      @cancel="cancelModal"
    >
      <div class="notice-container">
        <div class="notice-title">{{ chooseNotice?.notice_title }}</div>
        <div class="notice-time">{{ chooseNotice?.notice_time }}</div>
        <div v-html="chooseNotice?.notice_content"></div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
} from "@vue/runtime-core";
import { getNoticeList, getNoticeData } from "@/api/main.js";
import { getUserId } from "@/utils/storeData.js";
import { message } from "ant-design-vue";
import { isEqual } from "lodash-es";

export default defineComponent({
  name: "Notice",
  setup() {
    const pageData = reactive({
      notice: [],
      page: 1,
      limit: 16,
      total: 0,
      loading: false,
      user_id: computed(() => getUserId()).value,
      showNotice: false,
      chooseNotice: null,
    });
    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };
    const getNoticeListFun = () => {
      pageData.loading = true;
      getNoticeList({
        page: pageData.page,
        limit: pageData.limit,
        user_id: pageData.user_id,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.notice = res.data.notice;
            pageData.total = res.count;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    getNoticeListFun();

    watch(
      [() => pageData.page, () => pageData.limit, () => pageData.user_id],
      (newValue, oldValue) => {
        if (!isEqual(newValue, oldValue)) {
          getNoticeListFun();
        }
      }
    );
    const chooseNoticeFun = (item) => {
      getNoticeData({
        notice_id: item.notice_id,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.chooseNotice = res.data.notice;
            pageData.showNotice = true;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    };
    const cancelModal = () => {
      pageData.chooseNotice = null;
      pageData.showNotice = false;
    };

    return {
      ...toRefs(pageData),
      onShowSizeChange,
      cancelModal,
      chooseNoticeFun,
    };
  },
});
</script>

<style lang="less" scoped>
.notice-page {
  .notice-box {
    padding: 0 30px;
    margin-bottom: 40px;
    .notice-row {
      padding: 8px;
      .notice-title {
        font-size: 16px;
      }
      .notice-time {
        color: #999;
      }
      &:hover {
        background: #97cdff2b;
        color: #1890ff;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .ant-pagination {
    text-align: center;
  }
}
</style>
<style lang="less">
.notice-container {
  .notice-title {
    font-size: 18px;
    text-align: center;
    margin-bottom: 12px;
  }
  .notice-time {
    text-align: center;
    margin-bottom: 24px;
    color: #999;
  }
  img {
    max-width: 100%;
  }
}
</style>
